<template>
  <div style=" z-index:10; margin: 0px 10px;" :class="{ disabled }">
    <transition name="expand">
      <div v-if="open" class="email-alert">
        <span>Send to email:</span>
        <span class="close-btn" data-tool-tip="Close" @click="open = false">
          <font-awesome-icon :icon="['far', 'times']" class="check-on checkbox-active" />
        </span>
        <div v-if="emailStatus === 'success'">
          <span v-if="email === ''" class="success"
            >Report has been sent to user's email id successfully</span
          >
          <span v-else class="success">Report has been sent to provided email id successfully</span>
        </div>
        <div v-else-if="emailStatus === 'error'" class="fail">
          <span>Due to internal error, email has not been sent now. Please try later!</span>
        </div>
        <div v-if="error" class="error">{{ error }}</div>
        <input ref="emailInput" v-model.trim="email" type="email" @input="validateEmail" />
        <k-button :size="4" label="SEND" @click="sendEmail" />
      </div>
    </transition>
    <div class="flp-btn btn-size2 ex-dl-btn" @click="open = !open">
      EMAIL
      <span class="flp-btn-icon-size2">
        <font-awesome-icon :icon="['fal', 'envelope']" />
      </span>
    </div>
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import { EMAIL_VALIDATION_REGEX } from '@/constant';

export default {
  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    emailStatus: {
      required: false,
      type: String,
      default: '',
    },
    externalOpen: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      email: '',
      errorMsg: null,
    };
  },
  computed: {
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      return null;
    },
  },
  watch: {
    externalOpen(n) {
      this.open = n;
      if (!n) {
        this.email = '';
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.onClickOutside, true);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside, true);
  },
  methods: {
    onClickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.open = false;
      }
    },
    sendEmail() {
      if (this.validateEmail()) {
        this.$emit('email-to', this.email);
      }
    },
    clearFormErrors() {
      this.errorMsg = null;
      this.$refs.emailInput.classList.remove('errorBox');
    },
    validateEmail() {
      this.clearFormErrors();
      if (isBlank(this.email)) {
        return true;
      }
      if (!this.email.match(EMAIL_VALIDATION_REGEX)) {
        this.errorMsg = 'Please enter valid email id';
        this.$refs.emailInput.focus();
        this.$refs.emailInput.classList.add('errorBox');
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.errorBox {
  border: 1px solid #fc4242 !important;
}
.error {
  padding: 10px;
  font-size: 13px;
  color: rgba(252, 66, 66);
  letter-spacing: 0.9px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.close-btn {
  float: right;
}
.ex-dl-btn {
  color: #303335;
}
.expand-enter-active,
.expand-leave-active {
  -webkit-transition: top 0.3s ease, opacity 0.3s ease;
  -moz-transition: top 0.3s ease, opacity 0.3s ease;
  -ms-transition: top 0.3s ease, opacity 0.3s ease;
  -o-transition: top 0.3s ease, opacity 0.3s ease;
  transition: top 0.3s ease, opacity 0.3s ease;
}

.expand-enter,
.expand-leave-to {
  top: 100% !important;
  opacity: 0 !important;
}

.progress100 {
  width: 100% !important;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-12 {
  width: 0.75em;
}

@media screen {
  * {
    box-sizing: border-box;
  }
  * {
    outline: 0;
  }
  .email-alert {
    position: absolute;
    top: 15%;
    left: 76%;
    width: 300px;
    padding: 16px 30px;
    color: #fff;
    // text-align: center;
    background-color: rgba(29, 33, 37, 0.95);
    border-radius: 5px;
    transform: translateX(-20%);

    .success {
      font-size: 13px;
      color: rgba(107, 207, 24);
    }
    .fail {
      font-size: 13px;
      color: rgba(252, 66, 66);
    }

    input[type='email'] {
      width: 230px;
      margin: 0.5rem 0;
      font-size: 14px;
      line-height: 33px;
      border-radius: 0.15rem;
    }
  }
  .flp-btn {
    position: relative;
    display: inline-block;
    padding: 15px 26px;
    font-size: 12px;
    font-weight: 400;
    color: var(--buttontext) !important;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    cursor: pointer;
    user-select: none;
    background-color: var(--primarycolor) !important;
    -webkit-border-radius: 0;
    border-radius: 3px;
    transition: all 0.6s ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-touch-callout: none;
  }
  .btn-size2 {
    min-width: 20px;
    padding: 11px;
    padding-left: 17px;
    font-size: 12px;
    font-weight: 600;
    &:hover {
      color: #fff !important;
      background-color: #424242 !important;
      border: none !important;
    }
  }
  .flp-btn-icon-size2 {
    position: relative;
    top: 2px;
    padding: 5px 4px 4px 12px;
    margin: 0 0 0 13px;
    font-size: 18px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
  }
  .hide {
    display: none !important;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}

.fa-arrow-to-bottom:before {
  content: '\f33d';
}

.alt-loader {
  position: relative;
  display: block;
  width: 100%;
  height: 4px;
  margin: 11px 0px 2px 0px;
}
.alt-loader-bg {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
}
.alt-loader-progress {
  position: absolute;
  z-index: 3;
  width: 1%;
  height: 4px;
  background-color: #00abb7;
  -webkit-transition: width 1s ease;
  -moz-transition: width 1s ease;
  -ms-transition: width 1s ease;
  -o-transition: width 1s ease;
  transition: width 1s ease;
}
</style>
